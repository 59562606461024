import {
    define_units, define_formats, add_conversion, any_number, convert, render_from_lit, alias
} from './convertable.js';

import {
    format_dp, formatTwa, formatTableTwa, formatLatitude, formatLongitude,
    formatTimestamp, formatTimeDifference,
    isset, pad2
} from './routing-utils.js';

const WMM = {
  "epoch" : 2025,
  "model" : "WMM-2025",
  "modelDate" : "11\/13\/2024",
  "wmm" : [
    {
      "dgnm" : 12,
      "dhnm" : 0,
      "gnm" : -29351.799999999999,
      "hnm" : 0,
      "m" : 0,
      "n" : 1
    },
    {
      "dgnm" : 9.6999999999999993,
      "dhnm" : -21.5,
      "gnm" : -1410.8,
      "hnm" : 4545.3999999999996,
      "m" : 1,
      "n" : 1
    },
    {
      "dgnm" : -11.6,
      "dhnm" : 0,
      "gnm" : -2556.5999999999999,
      "hnm" : 0,
      "m" : 0,
      "n" : 2
    },
    {
      "dgnm" : -5.2000000000000002,
      "dhnm" : -27.699999999999999,
      "gnm" : 2951.0999999999999,
      "hnm" : -3133.5999999999999,
      "m" : 1,
      "n" : 2
    },
    {
      "dgnm" : -8,
      "dhnm" : -12.1,
      "gnm" : 1649.3,
      "hnm" : -815.10000000000002,
      "m" : 2,
      "n" : 2
    },
    {
      "dgnm" : -1.3,
      "dhnm" : 0,
      "gnm" : 1361,
      "hnm" : 0,
      "m" : 0,
      "n" : 3
    },
    {
      "dgnm" : -4.2000000000000002,
      "dhnm" : 4,
      "gnm" : -2404.0999999999999,
      "hnm" : -56.600000000000001,
      "m" : 1,
      "n" : 3
    },
    {
      "dgnm" : 0.40000000000000002,
      "dhnm" : -0.29999999999999999,
      "gnm" : 1243.8,
      "hnm" : 237.5,
      "m" : 2,
      "n" : 3
    },
    {
      "dgnm" : -15.6,
      "dhnm" : -4.0999999999999996,
      "gnm" : 453.60000000000002,
      "hnm" : -549.5,
      "m" : 3,
      "n" : 3
    },
    {
      "dgnm" : -1.6000000000000001,
      "dhnm" : 0,
      "gnm" : 895,
      "hnm" : 0,
      "m" : 0,
      "n" : 4
    },
    {
      "dgnm" : -2.3999999999999999,
      "dhnm" : -1.1000000000000001,
      "gnm" : 799.5,
      "hnm" : 278.60000000000002,
      "m" : 1,
      "n" : 4
    },
    {
      "dgnm" : -6,
      "dhnm" : 4.0999999999999996,
      "gnm" : 55.700000000000003,
      "hnm" : -133.90000000000001,
      "m" : 2,
      "n" : 4
    },
    {
      "dgnm" : 5.5999999999999996,
      "dhnm" : 1.6000000000000001,
      "gnm" : -281.10000000000002,
      "hnm" : 212,
      "m" : 3,
      "n" : 4
    },
    {
      "dgnm" : -7,
      "dhnm" : -4.4000000000000004,
      "gnm" : 12.1,
      "hnm" : -375.60000000000002,
      "m" : 4,
      "n" : 4
    },
    {
      "dgnm" : 0.59999999999999998,
      "dhnm" : 0,
      "gnm" : -233.19999999999999,
      "hnm" : 0,
      "m" : 0,
      "n" : 5
    },
    {
      "dgnm" : 1.3999999999999999,
      "dhnm" : -0.5,
      "gnm" : 368.89999999999998,
      "hnm" : 45.399999999999999,
      "m" : 1,
      "n" : 5
    },
    {
      "dgnm" : 0,
      "dhnm" : 2.2000000000000002,
      "gnm" : 187.19999999999999,
      "hnm" : 220.19999999999999,
      "m" : 2,
      "n" : 5
    },
    {
      "dgnm" : 0.59999999999999998,
      "dhnm" : 0.40000000000000002,
      "gnm" : -138.69999999999999,
      "hnm" : -122.90000000000001,
      "m" : 3,
      "n" : 5
    },
    {
      "dgnm" : 2.2000000000000002,
      "dhnm" : 1.7,
      "gnm" : -142,
      "hnm" : 43,
      "m" : 4,
      "n" : 5
    },
    {
      "dgnm" : 0.90000000000000002,
      "dhnm" : 1.8999999999999999,
      "gnm" : 20.899999999999999,
      "hnm" : 106.09999999999999,
      "m" : 5,
      "n" : 5
    },
    {
      "dgnm" : -0.20000000000000001,
      "dhnm" : 0,
      "gnm" : 64.400000000000006,
      "hnm" : 0,
      "m" : 0,
      "n" : 6
    },
    {
      "dgnm" : -0.40000000000000002,
      "dhnm" : 0.29999999999999999,
      "gnm" : 63.799999999999997,
      "hnm" : -18.399999999999999,
      "m" : 1,
      "n" : 6
    },
    {
      "dgnm" : 0.90000000000000002,
      "dhnm" : -1.6000000000000001,
      "gnm" : 76.900000000000006,
      "hnm" : 16.800000000000001,
      "m" : 2,
      "n" : 6
    },
    {
      "dgnm" : 1.2,
      "dhnm" : -0.40000000000000002,
      "gnm" : -115.7,
      "hnm" : 48.799999999999997,
      "m" : 3,
      "n" : 6
    },
    {
      "dgnm" : -0.90000000000000002,
      "dhnm" : 0.90000000000000002,
      "gnm" : -40.899999999999999,
      "hnm" : -59.799999999999997,
      "m" : 4,
      "n" : 6
    },
    {
      "dgnm" : 0.29999999999999999,
      "dhnm" : 0.69999999999999996,
      "gnm" : 14.9,
      "hnm" : 10.9,
      "m" : 5,
      "n" : 6
    },
    {
      "dgnm" : 0.90000000000000002,
      "dhnm" : 0.90000000000000002,
      "gnm" : -60.700000000000003,
      "hnm" : 72.700000000000003,
      "m" : 6,
      "n" : 6
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : 79.5,
      "hnm" : 0,
      "m" : 0,
      "n" : 7
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0.59999999999999998,
      "gnm" : -77,
      "hnm" : -48.899999999999999,
      "m" : 1,
      "n" : 7
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0.5,
      "gnm" : -8.8000000000000007,
      "hnm" : -14.4,
      "m" : 2,
      "n" : 7
    },
    {
      "dgnm" : 0.5,
      "dhnm" : -0.80000000000000004,
      "gnm" : 59.299999999999997,
      "hnm" : -1,
      "m" : 3,
      "n" : 7
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : 15.800000000000001,
      "hnm" : 23.399999999999999,
      "m" : 4,
      "n" : 7
    },
    {
      "dgnm" : -0.80000000000000004,
      "dhnm" : -1,
      "gnm" : 2.5,
      "hnm" : -7.4000000000000004,
      "m" : 5,
      "n" : 7
    },
    {
      "dgnm" : -0.80000000000000004,
      "dhnm" : 0.59999999999999998,
      "gnm" : -11.1,
      "hnm" : -25.100000000000001,
      "m" : 6,
      "n" : 7
    },
    {
      "dgnm" : 0.80000000000000004,
      "dhnm" : -0.20000000000000001,
      "gnm" : 14.199999999999999,
      "hnm" : -2.2999999999999998,
      "m" : 7,
      "n" : 7
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : 23.199999999999999,
      "hnm" : 0,
      "m" : 0,
      "n" : 8
    },
    {
      "dgnm" : 0.20000000000000001,
      "dhnm" : -0.20000000000000001,
      "gnm" : 10.800000000000001,
      "hnm" : 7.0999999999999996,
      "m" : 1,
      "n" : 8
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.5,
      "gnm" : -17.5,
      "hnm" : -12.6,
      "m" : 2,
      "n" : 8
    },
    {
      "dgnm" : 0.5,
      "dhnm" : -0.40000000000000002,
      "gnm" : 2,
      "hnm" : 11.4,
      "m" : 3,
      "n" : 8
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0.40000000000000002,
      "gnm" : -21.699999999999999,
      "hnm" : -9.6999999999999993,
      "m" : 4,
      "n" : 8
    },
    {
      "dgnm" : 0.29999999999999999,
      "dhnm" : -0.5,
      "gnm" : 16.899999999999999,
      "hnm" : 12.699999999999999,
      "m" : 5,
      "n" : 8
    },
    {
      "dgnm" : 0.20000000000000001,
      "dhnm" : -0.59999999999999998,
      "gnm" : 15,
      "hnm" : 0.69999999999999996,
      "m" : 6,
      "n" : 8
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.29999999999999999,
      "gnm" : -16.800000000000001,
      "hnm" : -5.2000000000000002,
      "m" : 7,
      "n" : 8
    },
    {
      "dgnm" : 0.20000000000000001,
      "dhnm" : 0.20000000000000001,
      "gnm" : 0.90000000000000002,
      "hnm" : 3.8999999999999999,
      "m" : 8,
      "n" : 8
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : 4.5999999999999996,
      "hnm" : 0,
      "m" : 0,
      "n" : 9
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : -0.29999999999999999,
      "gnm" : 7.7999999999999998,
      "hnm" : -24.800000000000001,
      "m" : 1,
      "n" : 9
    },
    {
      "dgnm" : 0.10000000000000001,
      "dhnm" : 0.29999999999999999,
      "gnm" : 3,
      "hnm" : 12.199999999999999,
      "m" : 2,
      "n" : 9
    },
    {
      "dgnm" : 0.29999999999999999,
      "dhnm" : -0.29999999999999999,
      "gnm" : -0.20000000000000001,
      "hnm" : 8.3000000000000007,
      "m" : 3,
      "n" : 9
    },
    {
      "dgnm" : -0.29999999999999999,
      "dhnm" : 0.29999999999999999,
      "gnm" : -2.5,
      "hnm" : -3.2999999999999998,
      "m" : 4,
      "n" : 9
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.20000000000000001,
      "gnm" : -13.1,
      "hnm" : -5.2000000000000002,
      "m" : 5,
      "n" : 9
    },
    {
      "dgnm" : 0.29999999999999999,
      "dhnm" : -0.10000000000000001,
      "gnm" : 2.3999999999999999,
      "hnm" : 7.2000000000000002,
      "m" : 6,
      "n" : 9
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : -0.20000000000000001,
      "gnm" : 8.5999999999999996,
      "hnm" : -0.59999999999999998,
      "m" : 7,
      "n" : 9
    },
    {
      "dgnm" : 0.10000000000000001,
      "dhnm" : 0.40000000000000002,
      "gnm" : -8.6999999999999993,
      "hnm" : 0.80000000000000004,
      "m" : 8,
      "n" : 9
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0.10000000000000001,
      "gnm" : -12.9,
      "hnm" : 10,
      "m" : 9,
      "n" : 9
    },
    {
      "dgnm" : 0.10000000000000001,
      "dhnm" : 0,
      "gnm" : -1.3,
      "hnm" : 0,
      "m" : 0,
      "n" : 10
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -6.4000000000000004,
      "hnm" : 3.2999999999999998,
      "m" : 1,
      "n" : 10
    },
    {
      "dgnm" : 0.10000000000000001,
      "dhnm" : 0,
      "gnm" : 0.20000000000000001,
      "hnm" : 0,
      "m" : 2,
      "n" : 10
    },
    {
      "dgnm" : 0.10000000000000001,
      "dhnm" : -0.20000000000000001,
      "gnm" : 2,
      "hnm" : 2.3999999999999999,
      "m" : 3,
      "n" : 10
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.10000000000000001,
      "gnm" : -1,
      "hnm" : 5.2999999999999998,
      "m" : 4,
      "n" : 10
    },
    {
      "dgnm" : -0.29999999999999999,
      "dhnm" : -0.10000000000000001,
      "gnm" : -0.59999999999999998,
      "hnm" : -9.0999999999999996,
      "m" : 5,
      "n" : 10
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.10000000000000001,
      "gnm" : -0.90000000000000002,
      "hnm" : 0.40000000000000002,
      "m" : 6,
      "n" : 10
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : 1.5,
      "hnm" : -4.2000000000000002,
      "m" : 7,
      "n" : 10
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : -0.10000000000000001,
      "gnm" : 0.90000000000000002,
      "hnm" : -3.7999999999999998,
      "m" : 8,
      "n" : 10
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.20000000000000001,
      "gnm" : -2.7000000000000002,
      "hnm" : 0.90000000000000002,
      "m" : 9,
      "n" : 10
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -3.8999999999999999,
      "hnm" : -9.0999999999999996,
      "m" : 10,
      "n" : 10
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : 2.8999999999999999,
      "hnm" : 0,
      "m" : 0,
      "n" : 11
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -1.5,
      "hnm" : 0,
      "m" : 1,
      "n" : 11
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.10000000000000001,
      "gnm" : -2.5,
      "hnm" : 2.8999999999999999,
      "m" : 2,
      "n" : 11
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : 2.3999999999999999,
      "hnm" : -0.59999999999999998,
      "m" : 3,
      "n" : 11
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.10000000000000001,
      "gnm" : -0.59999999999999998,
      "hnm" : 0.20000000000000001,
      "m" : 4,
      "n" : 11
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : -0.10000000000000001,
      "hnm" : 0.5,
      "m" : 5,
      "n" : 11
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -0.59999999999999998,
      "hnm" : -0.29999999999999999,
      "m" : 6,
      "n" : 11
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.10000000000000001,
      "gnm" : -0.10000000000000001,
      "hnm" : -1.2,
      "m" : 7,
      "n" : 11
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : 1.1000000000000001,
      "hnm" : -1.7,
      "m" : 8,
      "n" : 11
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : -1,
      "hnm" : -2.8999999999999999,
      "m" : 9,
      "n" : 11
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : -0.20000000000000001,
      "hnm" : -1.8,
      "m" : 10,
      "n" : 11
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : 2.6000000000000001,
      "hnm" : -2.2999999999999998,
      "m" : 11,
      "n" : 11
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -2,
      "hnm" : 0,
      "m" : 0,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -0.20000000000000001,
      "hnm" : -1.3,
      "m" : 1,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : 0.29999999999999999,
      "hnm" : 0.69999999999999996,
      "m" : 2,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : -0.10000000000000001,
      "gnm" : 1.2,
      "hnm" : 1,
      "m" : 3,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : 0.10000000000000001,
      "gnm" : -1.3,
      "hnm" : -1.3999999999999999,
      "m" : 4,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : 0.59999999999999998,
      "hnm" : 0,
      "m" : 5,
      "n" : 12
    },
    {
      "dgnm" : 0.10000000000000001,
      "dhnm" : 0,
      "gnm" : 0.59999999999999998,
      "hnm" : 0.59999999999999998,
      "m" : 6,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : 0.5,
      "hnm" : -0.10000000000000001,
      "m" : 7,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -0.10000000000000001,
      "hnm" : 0.80000000000000004,
      "m" : 8,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -0.40000000000000002,
      "hnm" : 0.10000000000000001,
      "m" : 9,
      "n" : 12
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : 0,
      "gnm" : -0.20000000000000001,
      "hnm" : -1,
      "m" : 10,
      "n" : 12
    },
    {
      "dgnm" : 0,
      "dhnm" : 0,
      "gnm" : -1.3,
      "hnm" : 0.10000000000000001,
      "m" : 11,
      "n" : 12
    },
    {
      "dgnm" : -0.10000000000000001,
      "dhnm" : -0.10000000000000001,
      "gnm" : -0.69999999999999996,
      "hnm" : 0.20000000000000001,
      "m" : 12,
      "n" : 12
    }
  ]
};

let has_displayed_wmm_error = false;

export function getWorldMagneticModel() {
    const app_wmm = window.app?.getData('WorldMagneticModel');
    if (app_wmm) {
        return app_wmm;
    }
    const date = new Date();
    if (!has_displayed_wmm_error && date.getUTCFullYear() >= WMM.epoch + 5) {
        has_displayed_wmm_error = true;
        console.error('Developers: convertable-units needs to be updated to the latest magnetic model');
    }
    return WMM;
}
// HACK: for atlas tooltips as an optional dependency
window.getWorldMagneticModel = getWorldMagneticModel;

const DEBUG = false;

const gettext = window.gettext;
const pgettext = window.pgettext;
const interpolate = window.interpolate;
const utc_timezone_text = pgettext("UTC timezone", "GMT");

// text
const label_seconds = pgettext('unit', 'Seconds');
const symbol_seconds = pgettext('unit','s');
const label_metres = pgettext('unit', 'Meters');
const symbol_metres = pgettext('unit','m');
const label_feet = pgettext('unit', 'Feet');
const symbol_feet = pgettext('unit','ft');
const label_nautical_miles = pgettext('unit', 'Nautical Miles');
const symbol_nautical_miles = pgettext('unit','NM');
const label_kilometres = pgettext('unit', 'Kilometers');
const symbol_kilometres = pgettext('unit','km');
const label_miles = pgettext('unit', 'Miles');
const symbol_miles = pgettext('unit', 'mi');
const label_knots = pgettext('unit','Knots');
const symbol_knots = pgettext('knots', 'kts');
const label_beaufort = pgettext('unit', 'Beaufort');
const symbol_beaufort = pgettext('unit', 'beau');
const label_metres_per_second = pgettext('unit', 'Meters Per Second');
const symbol_metres_per_second = pgettext('unit', 'm/s');
const label_kilometres_per_hour = pgettext('unit', 'Kilometers Per Hour');
const symbol_kilometres_per_hour = pgettext('unit', 'kph');
const label_miles_per_hour = pgettext('unit', 'Miles Per Hour');
const symbol_miles_per_hour = pgettext('unit', 'mph');
const label_degrees_true = pgettext('unit', 'True');
const symbol_degrees_true = gettext('&deg; true');
const label_degrees_magnetic = pgettext('unit', 'Magnetic');
const symbol_degrees_magnetic = gettext('&deg; mag');
const label_degrees_angle = pgettext('unit', 'Degrees');
const symbol_degrees_angle = gettext('&deg;');
const label_celsius = pgettext('unit', 'Celsius');
const symbol_celsius = gettext('&deg; C');
const label_fahrenheit = pgettext('unit', 'Fahrenheit');
const symbol_fahrenheit = gettext('&deg; F');
const label_millimetres_per_hour = pgettext('unit', 'Millimeters/Hour');
const symbol_miliimetres_per_hour = pgettext('unit', 'mm/hr');
const label_inches_per_hour = pgettext('unit', 'Inches/Hour');
const symbol_inches_per_hour = pgettext('unit', 'inches/hr');
const label_percent = pgettext('unit', 'Percent');
const symbol_percent = pgettext('unit', '%');
const label_hectopascals = pgettext('unit', 'hPa');
const symbol_hectopascals = pgettext('unit', 'hPa');
const label_millibars = pgettext('unit', 'millibar');
const symbol_millibars = pgettext('unit', 'millibar');
const label_joules_per_kilogram = gettext('J/kg');
const symbol_joules_per_kilogram = gettext('J/kg');
const label_kilojoules_per_kilogram = pgettext('unit', 'kJ/kg');
const symbol_kilojoules_per_kilogram = pgettext('unit', 'kJ/kg');
const label_lighting_strikes_per_100_square_kilometres = pgettext('unit', 'str/100km²');
const symbol_lighting_strikes_per_100_square_kilometres = pgettext('unit', 'str/100km²');
const label_litres = pgettext('unit', 'Litres');
const symbol_litres = pgettext('unit', 'L');
const label_gallons_us = pgettext('unit', 'Gallons US');
const symbol_gallons_us = pgettext('unit', 'gal US');
const label_gallons_uk = pgettext('unit', 'Gallons UK');
const symbol_gallons_uk = pgettext('unit', 'gal UK');
const label_g_force = gettext('g');
const symbol_g_force = gettext('g');
const label_slams_per_hour = gettext('Slams/Hour');
// Translators: xgettext:no-c-format
const symbol_slams_per_hour = pgettext('unit', '%');

const symbol_motoring = pgettext('motoring', 'M');


export const formats = {
    string_0dp: {},
    string_1dp: {},
    string_2dp: {},
    string_3dp: {},
    string_0dp_unit: {},
    string_1dp_unit: {},
    string_2dp_unit: {},
    string_3dp_unit: {},
    string_twa: {},
    string_table_twa: {},
    string_lat: {},
    string_lon: {},
    string_time: {},
    string_time_short: {},
    string_time_iso: {},
    string_time_duration: {},
    string_motoring: {},
    string_depth: {},
    string_boolean: {}
};
// define_formats not called inline, so text editor reference lookups still work
define_formats('formats', formats);

export const units = {
    boolean: { format:formats.string_boolean },

    seconds: { label:label_seconds, symbol:symbol_seconds, format:formats.string_1dp },
    timespan: { symbol:symbol_seconds, format:formats.string_1dp },
    timestamp: { format:formats.string_time },
    timestamp_short: { format:formats.string_time_short },
    timestamp_iso: { format:formats.string_time_iso },

    // distances
    metres: { label:label_metres, symbol:symbol_metres, format:formats.string_1dp },
    feet: { label:label_feet, symbol:symbol_feet, format:formats.string_1dp },
    nautical_miles: { label:label_nautical_miles, symbol:symbol_nautical_miles, format:formats.string_1dp },
    kilometres: { label:label_kilometres, symbol:symbol_kilometres, format:formats.string_1dp },
    miles: { label:label_miles, symbol:symbol_miles, format:formats.string_1dp },

    // speeds
    knots: { label:label_knots, symbol:symbol_knots, format:formats.string_1dp },
    beaufort: { label:label_beaufort, symbol:symbol_beaufort, format:formats.string_1dp },
    metres_per_second: { label:label_metres_per_second, symbol:symbol_metres_per_second, format:formats.string_1dp },
    kilometres_per_hour: { label:label_kilometres_per_hour, symbol:symbol_kilometres_per_hour, format:formats.string_1dp },
    miles_per_hour: { label:label_miles_per_hour, symbol:symbol_miles_per_hour, format:formats.string_1dp },

    // direction
    degrees_true: { label:label_degrees_true, symbol:symbol_degrees_true, format:formats.string_0dp },
    degrees_magnetic: { label:label_degrees_magnetic, symbol:symbol_degrees_magnetic, format:formats.string_0dp },
    degrees_angle: { label:label_degrees_angle, symbol:symbol_degrees_angle, format:formats.string_1dp },
    latitude: { format:formats.string_lat },
    longitude: { format:formats.string_lon },
    twa: { format:formats.string_twa },

    // temperatures
    celsius: { label:label_celsius, symbol:symbol_celsius, format:formats.string_1dp },
    fahrenheit: { label:label_fahrenheit, symbol:symbol_fahrenheit, format:formats.string_1dp },

    // rainfall
    millimetres_per_hour: { label:label_millimetres_per_hour, symbol:symbol_miliimetres_per_hour, format:formats.string_1dp },
    inches_per_hour: { label:label_inches_per_hour, symbol:symbol_inches_per_hour, format:formats.string_1dp },

    // ratios
    percent: { label:label_percent, symbol:symbol_percent, format:formats.string_1dp },

    // pressure
    kilopascals: { format:formats.string_1dp }, // from the router, not displayed
    hectopascals: { label:label_hectopascals, symbol:symbol_hectopascals, format:formats.string_1dp },
    millibars: { label:label_millibars, symbol:symbol_millibars, format:formats.string_1dp },

    // cape
    joules_per_kilogram: { label:label_joules_per_kilogram, symbol:symbol_joules_per_kilogram, format:formats.string_1dp },
    kilojoules_per_kilogram: { label:label_kilojoules_per_kilogram, symbol:symbol_kilojoules_per_kilogram, format:formats.string_1dp },

    // fuel/volume
    litres: { label:label_litres, symbol:symbol_litres, format:formats.string_1dp },
    gallons_us: { label:label_gallons_us, symbol:symbol_gallons_us, format:formats.string_1dp },
    gallons_uk: { label:label_gallons_uk, symbol:symbol_gallons_uk, format:formats.string_1dp },

    // various
    lightning_strikes_per_100_square_kilometres: { label:label_lighting_strikes_per_100_square_kilometres, symbol:symbol_lighting_strikes_per_100_square_kilometres, format:formats.string_3dp },
    fog_rime_factor: { format:formats.string_1dp },
    uv_index: { format:formats.string_1dp },
    g_force: { label:label_g_force, symbol:symbol_g_force, format:formats.string_2dp },
    slams_per_hour: { label:label_slams_per_hour, symbol:symbol_slams_per_hour, format:formats.string_0dp_unit },
};
// define_units not called inline, so text editor reference lookups still work
define_units('units', units);

function init() {
    add_conversion(units.timestamp, units.timestamp_short, alias);
    add_conversion(units.timestamp, units.timestamp_iso, alias);
    add_conversion(units.seconds, units.timespan, alias);

    // short distances (wave height)
    add_conversion(units.metres, units.feet, (value) => value * 3.2808399);
    add_conversion(units.feet, units.metres, (value) => value / 3.2808399);

    // mixed distances (interop)
    add_conversion(units.kilometres, units.metres, (value) => value * 1000);
    add_conversion(units.metres, units.kilometres, (value) => value / 1000);

    // large distances (travel distance)
    add_conversion(units.nautical_miles, units.kilometres, (value) => value * 1.852);
    add_conversion(units.kilometres, units.nautical_miles, (value) => value / 1.852);
    add_conversion(units.nautical_miles, units.miles, (value) => value * 1.15078);
    add_conversion(units.miles, units.nautical_miles, (value) => value / 1.15078);

    // speeds (knots based)
    add_conversion(units.knots, units.beaufort, (value) => Math.min(12, Math.pow(value / 1.6251, 2 / 3)));
    add_conversion(units.beaufort, units.knots, (value) => 1.6251 * Math.pow(value, 3 / 2));
    add_conversion(units.knots, units.metres_per_second, (value) => value * 0.5144);
    add_conversion(units.metres_per_second, units.knots, (value) => value / 0.5144);
    add_conversion(units.knots, units.kilometres_per_hour, (value) => value * 1.852);
    add_conversion(units.kilometres_per_hour, units.knots, (value) => value / 1.852);
    add_conversion(units.knots, units.miles_per_hour, (value) => value * 1.15078);
    add_conversion(units.miles_per_hour, units.knots, (value) => value / 1.15078);

    // directions
    add_conversion(units.degrees_true, units.degrees_magnetic, (value, context) => convertMagnetic(value, context, false));
    add_conversion(units.degrees_magnetic, units.degrees_true, (value, context) => convertMagnetic(value, context, true));

    // temperatures
    add_conversion(units.celsius, units.fahrenheit, (value) => value * 1.8 + 32);
    add_conversion(units.fahrenheit, units.celsius, (value) => (value - 32) / 1.8);

    // pressures
    add_conversion(units.kilopascals, units.hectopascals, (value) => value * 10);
    add_conversion(units.hectopascals, units.kilopascals, (value) => value / 10);
    add_conversion(units.millibars, units.hectopascals, (value) => value);
    add_conversion(units.hectopascals, units.millibars, (value) => value);

    // rainfall
    add_conversion(units.inches_per_hour, units.millimetres_per_hour, (value) => value * 25.4);
    add_conversion(units.millimetres_per_hour, units.inches_per_hour, (value) => value / 25.4);

    // cape
    add_conversion(units.kilojoules_per_kilogram, units.joules_per_kilogram, (value) => value * 1000);
    add_conversion(units.joules_per_kilogram, units.kilojoules_per_kilogram, (value) => value / 1000);

    // fuel/volume
    add_conversion(units.gallons_us, units.litres, value => value * 3.78541);
    add_conversion(units.litres, units.gallons_us, value => value / 3.78541);
    add_conversion(units.gallons_uk, units.litres, value => value * 4.54609);
    add_conversion(units.litres, units.gallons_uk, value => value / 4.54609);

    // formatting
    add_conversion(any_number, formats.string_0dp, wrap_formatter((value, context, from) => format_number(value, context, from, 0)));
    add_conversion(any_number, formats.string_1dp, wrap_formatter((value, context, from) => format_number(value, context, from, 1)));
    add_conversion(any_number, formats.string_2dp, wrap_formatter((value, context, from) => format_number(value, context, from, 2)));
    add_conversion(any_number, formats.string_3dp, wrap_formatter((value, context, from) => format_number(value, context, from, 3)));
    add_conversion(any_number, formats.string_0dp_unit, wrap_formatter((value, context, from) => format_number(value, {...context, show_units:true}, from, 0)));
    add_conversion(any_number, formats.string_1dp_unit, wrap_formatter((value, context, from) => format_number(value, {...context, show_units:true}, from, 1)));
    add_conversion(any_number, formats.string_2dp_unit, wrap_formatter((value, context, from) => format_number(value, {...context, show_units:true}, from, 2)));
    add_conversion(any_number, formats.string_3dp_unit, wrap_formatter((value, context, from) => format_number(value, {...context, show_units:true}, from, 3)));
    add_conversion(any_number, formats.string_twa, wrap_formatter(formatTwa));
    add_conversion(any_number, formats.string_table_twa, wrap_formatter(formatTableTwa));
    add_conversion(units.boolean, formats.string_boolean, wrap_formatter((value) => value ? 'true' : 'false')); // not intended for production
    add_conversion(units.boolean, formats.string_motoring, wrap_formatter((value) => value ? symbol_motoring : ' '));

    add_conversion(units.latitude, formats.string_lat, wrap_formatter(formatLatitude));
    add_conversion(units.longitude, formats.string_lon, wrap_formatter(formatLongitude));
    add_conversion(units.timestamp, formats.string_time, wrap_formatter((value, context) => formatWeatherTimestamp(value, context.utcOffset, true)));
    add_conversion(units.timestamp_short, formats.string_time_short, wrap_formatter((value, context) => formatTimestamp(value + context.utcOffset * 3600, false)));
    add_conversion(units.timestamp_iso, formats.string_time_iso, wrap_formatter((value, context) => new Date(value + context.utcOffset).toISOString()));

    add_conversion(units.timespan, formats.string_time_duration, wrap_formatter((value) => formatTimeDifference(value)));
}

init();

function format_number(value, context, from, dp) {
    if (context?.show_units) {
        return formatField(value, from, n => format_dp(n, dp));
    } else {
        return format_dp(value, dp);
    }
}

export function convertAndFormat(value, from, to, format, context) {
    if (!format) throw new Error('convertAndFormat: format required');
    return convert(convert(value, from, to, context), to, format, context);
}

export function isMagneticAvailable() {
    return !!getGeoMag();
}

export function getUnitLabel(unit) {
    return replace_entities(DEBUG ? `[${unit.label}]` : unit.label);
}
export function getUnitSymbol(unit) {
    return replace_entities(DEBUG ? `[${unit.symbol}]` : unit.symbol);
}

export function getUnitSuffix(unit) {
    const suffix = _getSuffix(unit);
    return DEBUG ? `[${suffix}]` : suffix;
}

let global_geoMag = null;

function getGeoMag() {
    if (!global_geoMag && window.geoMagFactory) {
        const wmm = getWorldMagneticModel();
        if (wmm) {
            global_geoMag = geoMagFactory(wmm);
        }
    }
    return global_geoMag;
}

function getMagneticDeclination(lat, lon) {
    const geoMag = getGeoMag();
    if (!geoMag) {
        return null;
    }
    const mag = geoMag(lat, lon, 0);
    return mag.dec;
}

function convertMagnetic(value, context, reverse) {
    if (value === null) return null;
    const decl = context.mag_decl ?? getMagneticDeclination(context.lat, context.lon);
    if (decl === null) {
        return null;
    }
    const val = reverse ? value + decl : value - decl;
    return (36000 + val) % 360;
}

const entity_map = {amp:'&',lt:'<',gt:'>',nbsp:' ',iexcl:'¡',cent:'¢',pound:'£',curren:'¤',yen:'¥',brvbar:'¦',sect:'§',uml:'¨',copy:'©',ordf:'ª',laquo:'«',not:'¬',shy:'­',reg:'®',macr:'¯',deg:'°',plusmn:'±',sup2:'²',sup3:'³',acute:'´',micro:'µ',para:'¶',cedil:'¸',sup1:'¹',ordm:'º',raquo:'»',frac14:'¼',frac12:'½',frac34:'¾',iquest:'¿',times:'×',divide:'÷',forall:'∀',part:'∂',exist:'∃',empty:'∅',nabla:'∇',isin:'∈',notin:'∉',ni:'∋',prod:'∏',sum:'∑',minus:'−',lowast:'∗',radic:'√',prop:'∝',infin:'∞',ang:'∠',and:'∧',or:'∨',cap:'∩',cup:'∪',int:'∫',there4:'∴',sim:'∼',cong:'≅',asymp:'≈',ne:'≠',equiv:'≡',le:'≤',ge:'≥',sub:'⊂',sup:'⊃',nsub:'⊄',sube:'⊆',supe:'⊇',oplus:'⊕',otimes:'⊗',perp:'⊥',sdot:'⋅',Alpha:'Α',Beta:'Β',Gamma:'Γ',Delta:'Δ',Epsilon:'Ε',Zeta:'Ζ',Eta:'Η',Theta:'Θ',Iota:'Ι',Kappa:'Κ',Lambda:'Λ',Mu:'Μ',Nu:'Ν',Xi:'Ξ',Omicron:'Ο',Pi:'Π',Rho:'Ρ',Sigma:'Σ',Tau:'Τ',Upsilon:'Υ',Phi:'Φ',Chi:'Χ',Psi:'Ψ',Omega:'Ω',alpha:'α',beta:'β',gamma:'γ',delta:'δ',epsilon:'ε',zeta:'ζ',eta:'η',theta:'θ',iota:'ι',kappa:'κ',lambda:'λ',mu:'μ',nu:'ν',xi:'ξ',omicron:'ο',pi:'π',rho:'ρ',sigmaf:'ς',sigma:'σ',tau:'τ',upsilon:'υ',phi:'φ',chi:'χ',psi:'ψ',omega:'ω',thetasym:'ϑ',upsih:'ϒ',piv:'ϖ',OElig:'Œ',oelig:'œ',Scaron:'Š',scaron:'š',Yuml:'Ÿ',fnof:'ƒ',circ:'ˆ',tilde:'˜',ensp:' ',emsp:' ',thinsp:' ',zwnj:'‌',zwj:'‍',lrm:'‎',rlm:'‏',ndash:'–',mdash:'—',lsquo:'‘',rsquo:'’',sbquo:'‚',ldquo:'“',rdquo:'”',bdquo:'„',dagger:'†',Dagger:'‡',bull:'•',hellip:'…',permil:'‰',prime:'′',Prime:'″',lsaquo:'‹',rsaquo:'›',oline:'‾',euro:'€',trade:'™',larr:'←',uarr:'↑',rarr:'→',darr:'↓',harr:'↔',crarr:'↵',lceil:'⌈',rceil:'⌉',lfloor:'⌊',rfloor:'⌋',loz:'◊',spades:'♠',clubs:'♣',hearts:'♥',diams:'♦'};
function replace_entities(str) {
    return str.replaceAll(/&(\w+);/g, (entity, entityName) => {
        if (entityName in entity_map) {
            return entity_map[entityName];
        }
        return entity;
    });
}

function formatField(value, type, formatter, blank) {
    if (isset(value)) {
        if (typeof formatter === 'object') {
            return formatObject(value, type, formatter, blank);
        } else {
            const valueString = formatter(value);
            const suffix = _getSuffix(type);
            if (suffix) {
                return interpolate('%s%s', [valueString, suffix]);
            } else {
                return valueString;
            }
        }
    } else {
        return blank ?? '';
    }
}

function formatObject(values, types, formatters, blank) {
    const result = {};
    for (const key in formatters) {
        result[key] = formatField(values[key], types[key], formatters[key], blank);
    }
    return result;
}

function formatTimezoneShort(utcOffset) {
    const h = Math.floor(Math.abs(utcOffset));
    const m = Math.floor(Math.abs(utcOffset) * 60) % 60;
    return [utc_timezone_text,
        utcOffset !== 0
            ? (utcOffset >= 0 ? '+' : '-') + h + (m > 0 ? ':' + (m < 10 ? '0' : '') + m : '')
            : '' ];
}

function formatWeatherTimestamp(timestamp, utcOffset, showYear, showOffset) {
    if (timestamp === null || isNaN(timestamp)) {
        if (typeof timestamp === 'string') {
            return timestamp;
        } else {
            return '';
        }
    }
    if (!timestamp) {
        return '';
    }
    if (typeof showOffset == 'undefined') showOffset = true;

    const date = new Date();
    date.setTime((timestamp + utcOffset * 3600) * 1000);

    let result = [];
    result.push(window.GET_DAY_NAME_3(date.getUTCDay()), ' ', date.getUTCDate(), '/', window.GET_MONTH_NAME_3(date.getUTCMonth()));
    if (showYear) {
        result.push(' ', date.getUTCFullYear(), ',');
    }
    result.push(' ', pad2(date.getUTCHours()), ':', pad2(date.getUTCMinutes()));
    if (showOffset) {
        result.push(' ', formatTimezoneShort(utcOffset));
    }
    return result;
}

function wrap_formatter(fn) {
    return (value, context, from, to) => {
        if (value === null) return null;
        if (!DEBUG) return fn(value, context, from, to);
        let result = render_from_lit(fn(value, context, from, to));
        if (result !== null && from.label) {
            return `[${result} / ${from.label}]`;
        } else {
            return `[${result ?? from.label}]`;
        }
    };
}

function _getSuffix(type) {
    const symbol = replace_entities(type.symbol ?? '');
    if (symbol) {
        // may need to change this if we add support for RTL languages
        if ((/^[0-9a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]/).test(symbol)) {
            // suffixes starting with any number or latin letter needs a space
            return interpolate(' %s', [symbol]);
        } else {
            // e.g. ° ' " should not have spaces
            return interpolate('%s', [symbol]);
        }
    } else {
        return '';
    }
}

